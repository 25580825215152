import { pickupDate } from '@/utils/pickupDate';
import { useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';
export interface pickupStateType {
  nextDay: number;
  nextDayTwo?: number;
  pickupCase: number;
}

export const usePickupTime = () => {
  const [localPickupTime, _] = useLocalStorage('pickupTime', {});
  const { currentDate, hour, minute, getPickupCase } = pickupDate();
  const [pickupTime, setPickupTime] = useState(localPickupTime.earlyPickupTime);
  const [pickupTimeTwo, setPickupTimeTwo] = useState(localPickupTime.latePickupTime);
  const [pickupHour, setPickupHour] = useState<number>(12);
  const pickupCase = getPickupCase();
  const isBeforeFourHour = hour < 16; //4시 이전
  const isBetweenTime = hour === 15 && minute >= 30; //3시반 ~ 4시

  useEffect(() => {
    if (isBetweenTime) {
      setPickupTime(localPickupTime.earlyPickupTime);
      setPickupTimeTwo(localPickupTime.latePickupTime);
    } else if (isBeforeFourHour) {
      setPickupTime(localPickupTime.earlyPickupTime);
      setPickupHour(7); //4시 이전 주문이면 7시
      setPickupTimeTwo('');
    } else {
      setPickupTime(localPickupTime.latePickupTime);
      setPickupHour(12); //4시 이후 주문이면 12시
      setPickupTimeTwo('');
    }
  }, [currentDate]);

  return { pickupTime, pickupTimeTwo, pickupCase, pickupHour };
};
