import moment from 'moment';
import { daysArr } from './daysArr';

//픽업 스케줄 케이스
//1. 월 ~목
//2. 금
//3. 주말

moment.updateLocale('ko', {
  week: {
    dow: 1 // 한 주의 시작 요일은 월요일로 변경
  }
});

export const format = 'YYYY/MM/DD';

export const pickupDate = () => {
  let currentDate = moment();
  const day = daysArr[currentDate.day()];
  const hour = currentDate.hour();
  const minute = currentDate.minute();

  const getPickupCase = () => {
    if (day === '토' || day === '일') return 3;
    if (day === '금') return 2;
    return 1;
  };

  return { currentDate, day, hour, minute, getPickupCase };
};
